<template>
    <div class="small-blog" v-if="posts.length">
        <b-container>
            <div class="title text-center mb-4"> Acompanhe nosso blog! </div>
            <b-row>
                <b-col cols="12" lg="6" v-for="(post, index) in posts" :key="post.id" class="post mt-3 mb-2">
                    <a :href="post.route" class="row text">
                        <b-col cols="12" lg="5" :class="{'order-lg-1': index % 2}">
                            <div :class="index % 2 != 0 ? 'right-side' : ''">
                                <span class="month text-uppercase">
                                    {{ ($moment(post.datetime).format('MMM')) }}
                                    <span class="day"> {{ ($moment(post.datetime).format('DD')) }} </span>
                                </span>
                                <div class="title"> {{ post.title }}</div>
                                <div class="mt-2 mb-4">
                                    <div class="text">
                                        <div class="link">Leia mais &gt;</div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="7" class="mb-4">
                            <div class="aspect-ratio ratio-4-3">
                                <div class="aspect-content">
                                    <img class="object-fit-cover" v-lazy="imageUrl(post.image)" :alt="post.title">
                                </div>
                            </div>
                        </b-col>
                    </a>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-4 mb-5">
                <a href="/blog/" class="btn-default btn-see-more">
                        VEJA MAIS
                </a>
            </div>
        </b-container>
    </div>
</template>
<script>
import utils from '~/mixins/utils'

export default {
    mixins: [utils],
    data() {
        return {
            pretitle: 'Acompanhe nossas',
            title: 'Dicas e notícias',
            posts: []
        }
    },
    async fetch() {
        await this.$axios.$get('api/v1/blogs/posts?&blog_id=1&limit=4').then((res) => {
            this.posts = this.$wdmerge.blogpost(res, true)
        }).catch((e) => {
            console.error(e)
        })
    }
}
</script>
