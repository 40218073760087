<template>
    <b-modal v-model="show" hide-footer title="Selecione as opções" size="md" body-class="pt-0">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer" tag="div">
            <div class="row" v-if="product.variations.list.length">
                <div class="col-12 mb-2" v-for="variation in product.variations.list" :key="variation.id">
                    <ValidationProvider :name="variation.name" rules="required|numberpositive" tag="div" v-slot="{ errors, classes, required }">
                        <label class="form-label mb-1" :for="uid + '_variation_' + variation.id">{{ variation.name }}</label>
                        <select
                            :id="uid + '_variation_' + variation.id"
                            v-model="variation.value"
                            @focus="clickChangeVariation(variation)"
                            @change="changeVariation(true)"
                            class="form-control"
                        >
                            <option :disabled="option.disabled" v-for="option in variation.options" :key="option.value" :value="option.value">
                                {{option.text}}
                            </option>
                        </select>
                        <div class="invalid-feedback d-block" v-show="errors[0]">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
            </div>
            <b-row>
                <b-col>
                    <div class="d-flex flex-column flex-sm-row align-items-end py-2">
                        <div class="w-100 pr-sm-2 mb-3 mb-sm-0" style="line-height: 1.25">
                            <b>{{ product.price | currency }}</b>
                            <div>
                                <small v-if="product.payment_condition && product.payment_condition.installments > 1">ou {{ product.payment_condition.installments}} x {{(product.price/product.payment_condition.installments) | currency}}</small>
                                <small v-if="$wdconfig.modules.product.payment_discount && product.payments && product.payments.length">ou {{ parseFloat(product.payments[0]['discount']['value']) }}% de desconto no {{ product.payments[0]['name'] }}</small>
                            </div>
                        </div>
                        <button type="button" class="btn-default ml-auto btn-square px-5" @click.prevent="handleSubmit(onSubmit)">
                            {{ $wdconfig.modules.product.add_to_cart_button_text }}
                        </button>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
    </b-modal>
</template>

<style type="scss">
    @media screen and (max-width: 575px) {
        .btn-default {
            width: 100%;
        }
    }
</style>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import utils from '~/mixins/utils'
    import order from "~/mixins/order";
    import product from "~/mixins/product";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        mixins: [
            utils,
            order,
            product
        ],
        props: {
            product: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                product_code: 0,
                show: false,
                modal: null
            }
        },
        computed: {
            uid() {
                return this._uid
            }
        },
        methods: {
            async onSubmit() {
                try {
                    this.$refs['observer'].validate().then(isValid => {
                        if (isValid) {
                            this.addProduct(this.product)
                            this.show = false
                        }
                    })
                } catch (e) {
                    console.error(e)
                }
            },
            showModal() {
                this.show = true
            }
        }
    }
</script>
