<template>
    <div class="showcase pb-3" v-if="showcase.id">
        <div class="showcase-products mb-4 pt-4 pl-1 pr-1">
            <div class="title text-center"> {{ showcase.name }}</div>
        </div>
        <b-container>
            <b-row class="mx-n2">
                <b-col cols="6" :md="$wdconfig.modules.product.size_in_listing === 'large' ? 4 : 3" class="mb-3 px-2" v-for="product in showcase.products" :key="product.code">
                    <Product :product="product"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import Product from '~/components/Product'

    export default {
        components: {
            Product
        },
        props: {
            area: {
                default: 0
            }
        },
        data() {
            return {
                showcase: {
                    id: 0,
                    name: '',
                    image: '',
                    alias: '',
                    products: []
                }
            }
        },
        async fetch() {
            this.$axios.$get(`api/v1/ecommerce/products/?showcase_code=${this.area}&limit=24&show_showcases=true&thumb=sim&x=340&show_payments=true`).then((res) => {
                try {
                    if (res.status === 'success' && res.data.length) {
                        this.showcase = Object.assign(res.metadata.showcases[0], {products: res.data})
                    }
                } catch (e) {
                    console.error(e)
                }
            }).catch((e) => {
                console.error(e)
            })
        }
    }
</script>
