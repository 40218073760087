<template>
    <div class="multiple-banner">
        <div v-if="primaryBanner.length || secondaryBanner.length" class="mt-4">
            <b-container>
                <b-row>
                    <b-col :md="secondaryBanner.length ? '8' : '12'" v-if="primaryBanner.length" class="mb-4">
                        <swiper class="swiper" :options="swiperOption">
                            <swiper-slide v-for="image in primaryBanner" :key="image.id">
                                <component :is="image.link ? 'a' : 'div'" v-bind="image.link ? {href: image.link, target: image.target} : ''" @click.prevent="image.link ? url_redirect(image.link, image.target) : ''">
                                    <div class="aspect-ratio ratio-16-9">
                                        <div class="aspect-content">
                                            <img class="object-fit-cover" :src="image_cache(image.file, image.file_cache1140)" width="1140" height="641" :alt="image.name" :title="image.name">
                                        </div>
                                    </div>
                                </component>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </b-col>
                    <b-col md="4" v-if="secondaryBanner.length">
                        <div v-for="image in secondaryBanner" :key="image.id">
                            <component :is="image.link ? 'a' : 'div'" v-bind="image.link ? {href: image.link, target: image.target} : ''" @click.prevent="image.link ? url_redirect(image.link, image.target) : ''">
                                <div class="aspect-ratio ratio-16-9 mb-3">
                                    <div class="aspect-content">
                                        <img class="object-fit-cover" :src="image_cache(image.file, image.file_cache380)" width="380" height="214" :alt="image.name" :title="image.name">
                                    </div>
                                </div>
                            </component>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    import utils from '~/mixins/utils';
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";

    export default {
        mixins: [utils],
        props: {
            primaryBanner: {
                default: () => [],
                type: Array
            },
            secondaryBanner: {
                default: () => [],
                type: Array
            },
            size: {
                type: String, Number,
                default: ''
            },
            loading: {
                type: String,
                default: ''
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                swiperOption: {
                    lazy: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                }
            }
        }
    }
</script>
