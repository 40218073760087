<template>
    <div>
        <ShowcaseSliderProducts :area="area" v-for="area in areas" :key="area"/>
    </div>
</template>

<script>
    import ShowcaseSliderProducts from "~/components/ShowcaseSliderProducts";

    export default {
        components: {
            ShowcaseSliderProducts
        },
        data() {
            return {
                areas: []
            }
        },
        async fetch() {
            if (!!this.$wdconfig.modules.showcase.home_area_carrousel) {
                await this.$axios.$get(`/?p=ecommerce_produtos_showcases_areas_api_v1&area=${this.$wdconfig.modules.showcase.home_area_carrousel}`).then((res) => {
                    if (res.status === 'success' && res.data) {
                        let areas = []
                        res.data.forEach(el => {
                            areas.push(el.id)
                        })
                        this.areas = areas
                    }
                }).catch((e) => {
                    console.error(e)
                })
            }
        }
    }
</script>
