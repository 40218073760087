<template>
    <div v-if="banner.length">
        <div class="banner-slider-mobile">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="image in banner" :key="image.id">
                    <template v-if="image.link">
                        <a :href="image.link" :target="image.target"
                           @click.prevent="url_redirect(image.link, image.target)">
                            <div class="aspect-ratio ratio-16-9 mb-2">
                                <div class="aspect-content">
                                    <img :src="image_cache(image.file, image.file_cache)" width="790" height="444" class="object-fit-cover" :alt="image.name" :title="image.name">
                                </div>
                            </div>
                        </a>
                    </template>
                    <template v-else>
                        <div class="aspect-ratio ratio-16-9 mb-2">
                            <div class="aspect-content">
                                <img :src="image_cache(image.file, image.file_cache)" width="790" height="444" class="object-fit-cover" :alt="image.name" :title="image.name">
                            </div>
                        </div>
                    </template>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
    import utils from "~/mixins/utils";
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        mixins: [
            utils
        ],
        data() {
            return {
                banner: [],
                swiperOption: {
                    lazy: true,
                    spaceBetween: 10,
                    infinite: true,
                    loop: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: false
                    },
                }
            }
        },
        props: {
            areas: {
                type: Array,
                default: () => []
            }
        },
        async fetch() {
            try {
                let url = `api/v1/banner/area/?area[]=${this.areas.join('&area[]=')}&thumb[x]=790`
                await this.$axios.$get(url).then((res) => {
                    this.areas.forEach(area => {
                        if (typeof res[area] === 'object') {
                            this.banner = this.banner.concat(res[area])
                        }
                    })
                }).catch((e) => {
                    console.error(e)
                })
            } catch (e) {
                console.error(e)
            }
        }
    }
</script>
